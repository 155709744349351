export class GuideModel {
  firstName = "";
  lastName = "";
  age = 0;
  gender = "";
  city = "";
  phoneNumber = "";
  cin = "";
  passportNumber = "";
  clientId = 0;
}
