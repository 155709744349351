import { Axios } from "../Config/api.config";

export const createAPIEndpoint = (
  endpoint,
  params = { page: 1, take: 20, q: "" },
  custom_url = ""
) => {
  let url = endpoint + custom_url + "/";
  const headers = {
    Authorization:
      "Bearer " +
      (localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")).token
        : ""),
  };
  let options = { headers, params };
  return {
    fetchAll: () => Axios.get(url, { headers }),
    fetch: () => Axios.get(url, options),
    fetchById: (id) => Axios.get(url + id, { headers }),
    create: (p) => Axios.post(url, p, { headers }),
    update: (updatedModel) => Axios.put(url, updatedModel, { headers }),
    updateWithId: (id, updatedModel) =>
      Axios.put(url + id, updatedModel, { headers }),
    delete: (id) => Axios.delete(url + id, { headers }),
    upload: (fd) => Axios.post(url, fd, { headers }),
  };
};
