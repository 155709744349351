import React from "react";
import Responsive from "../../Components/Responsive";
import { GoodOrBadResponse, worstOf } from "../../data/data";
import { Checkbox } from "rsuite";
import PageTopIcon from "@rsuite/icons/PageTop";

const AddSurvey = ({ model, _setmodel }) => {
  return (
    <div>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
          }}
        >
          تحديد موعد زيارة المعتمرين(قبل مغادرة المدينة بيوم) و التواصل مع رئيس
          المجموعة قبل الاستبيان؟
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
            marginTop: 10,
          }}
        >
          التاكد من عدم وجود اي شخص (بما في ذلك رئيس المجموعة) عيد زيارة
          المعتمرين في غرفهم للاستبيان؟
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان التنظيم قبل الرحلة في مطار تونس و في طيّارة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(OrganizationInTunisia) => {
                    _setmodel((prev) => {
                      return { ...prev, OrganizationInTunisia };
                    });
                  }}
                  checked={
                    model.OrganizationInSaoudi == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان التنظيم و الاستقبال في المطار عند الوصول الى السعودية؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(OrganizationInSaoudi) => {
                    _setmodel((prev) => {
                      return { ...prev, OrganizationInSaoudi };
                    });
                  }}
                  checked={
                    model.OrganizationInSaoudi == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كانت اللهوة بيكم من طرف المرافق في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(GuideReview) => {
                    _setmodel((prev) => {
                      return { ...prev, GuideReview };
                    });
                  }}
                  checked={model.GuideReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان تقييمك لمزارات المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(MadinahPlacesReview) => {
                    _setmodel((prev) => {
                      return { ...prev, MadinahPlacesReview };
                    });
                  }}
                  checked={model.MadinahPlacesReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش تقييمك للاحاطة الدينية في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(MadinahReligiousbriefing) => {
                    _setmodel((prev) => {
                      return { ...prev, MadinahReligiousbriefing };
                    });
                  }}
                  checked={
                    model.MadinahReligiousbriefing == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش لقيت فندق المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(madinahHotelReview) => {
                    _setmodel((prev) => {
                      return { ...prev, madinahHotelReview };
                    });
                  }}
                  checked={model.madinahHotelReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          شنية الحاجة اللي ما عجبتكش في خدمات كنوز طول فترة اقامتك في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {worstOf.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(madinahGeneralReview) => {
                    _setmodel((prev) => {
                      return { ...prev, madinahGeneralReview };
                    });
                  }}
                  checked={model.customerType == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
    </div>
  );
};

export default AddSurvey;
