import React, { useEffect } from "react";
import { Checkbox, InputPicker, SelectPicker } from "rsuite";
import {
  BestInKounouz,
  branchTreatment,
  goodBadResponse,
  guide,
  heardOf,
  UmrahState,
  yesOrNoResponse,
} from "../../data/filterData";

const AdvancedSearch = ({ filterModel, _setfilterModel }) => {
  useEffect(() => console.log(filterModel), [filterModel]);
  return (
    <div>
      <div
        // className="p-10"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
          borderTop: "solid 2px #eeeeee",
          borderBottom: "solid 2px #eeeeee",
          // borderRadius: 10,
          padding: 3,
        }}
      >
        <div
          style={{
            padding: 15,
          }}
        >
          <Checkbox
            checked={filterModel.isFirstTime ? true : false}
            value={filterModel.isFirstTime}
            onChange={(isFirstTime) => {
              _setfilterModel((prev) => ({
                ...prev,
                isFirstTime: !isFirstTime,
              }));
            }}
          />
          <span>{" أوّل مرّة "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <Checkbox
            checked={filterModel.isRecommended}
            value={filterModel.isRecommended}
            onChange={(isRecommended) =>
              _setfilterModel((prev) => ({
                ...prev,
                isRecommended: !isRecommended,
              }))
            }
          />
          <span>{" اقتراح كنوز للنّاس"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <Checkbox
            checked={filterModel.isCouponUsed ? true : false}
            value={filterModel.isCouponUsed}
            onChange={(isCouponUsed) =>
              _setfilterModel((prev) => ({
                ...prev,
                isCouponUsed: !isCouponUsed,
              }))
            }
          />
          <span>{" استعمال رمز التخفيض"}</span>
        </div>
      </div>
      <div
        className="p-10"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
        }}
      >
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={heardOf}
            value={filterModel.heardOf}
            onChange={(heardOf) =>
              _setfilterModel((prev) => ({ ...prev, heardOf }))
            }
          />
          <span>{": طريقة السّماع عن كنوز "}</span>
        </div>

        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={UmrahState}
            value={filterModel.generalReview}
            onChange={(generalReview) =>
              _setfilterModel((prev) => ({ ...prev, generalReview }))
            }
          />
          <span>{" الرّضاء على  العمرة "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={branchTreatment}
            value={filterModel.branchReview}
            onChange={(branchReview) =>
              _setfilterModel((prev) => ({ ...prev, branchReview }))
            }
          />
          <span>{" الرّضاء على الفرع  "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={guide}
            value={filterModel.guideReview}
            onChange={(guideReview) =>
              _setfilterModel((prev) => ({ ...prev, guideReview }))
            }
          />
          <span>{" مرافق "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.airlineReview}
            onChange={(airlineReview) =>
              _setfilterModel((prev) => ({ ...prev, airlineReview }))
            }
          />
          <span>{" الطيران"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.madinahHotelReview}
            onChange={(madinahHotelReview) =>
              _setfilterModel((prev) => ({ ...prev, madinahHotelReview }))
            }
          />
          <span>{" فندق المدينة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.madinahServiceReview}
            onChange={(madinahServiceReview) =>
              _setfilterModel((prev) => ({ ...prev, madinahServiceReview }))
            }
          />
          <span>{" خدمات المدينة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.makkahHotelReview}
            onChange={(makkahHotelReview) =>
              _setfilterModel((prev) => ({ ...prev, makkahHotelReview }))
            }
          />
          <span>{" فندق مكّة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.makkahServiceReview}
            onChange={(makkahServiceReview) =>
              _setfilterModel((prev) => ({ ...prev, makkahServiceReview }))
            }
          />
          <span>{" خدمات مكّة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={BestInKounouz}
            value={filterModel.bestOf}
            onChange={(bestOf) =>
              _setfilterModel((prev) => ({ ...prev, bestOf }))
            }
          />
          <span>{" مميّزات كنوز"}</span>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
