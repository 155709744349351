import React, { useEffect, useState } from "react";
import {
  CheckPicker,
  Input,
  InputNumber,
  InputPicker,
  SelectPicker,
} from "rsuite";
import { genders } from "../../data/data";
import { createAPIEndpoint } from "../../API/authenticated.request";

const AddEdit = ({ model, _setmodel, error }) => {
  const [Guide, setGuide] = useState([]);
  const getGuides = () => {
    createAPIEndpoint("Guide/getAll")
      .fetchAll()
      .then((res) =>
        setGuide(
          res.data.map((el) => ({
            label: el.firstName + " " + el.lastName,
            value: el.lastName + " " + el.firstName,
          }))
        )
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => getGuides(), []);

  return (
    <div>
      <div style={{ backgroundColor: "#4d4cac", color: "white", padding: 15 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "24%" }}>
            <label htmlFor="">Nom : </label>
            <Input
              value={model.lastName}
              onChange={(lastName) => {
                _setmodel((prev) => {
                  return { ...prev, lastName };
                });
              }}
            />
          </div>
          <div style={{ width: "24%" }}>
            <label htmlFor="">Prenom : </label>
            <Input
              value={model.firstName}
              onChange={(firstName) => {
                _setmodel((prev) => {
                  return { ...prev, firstName };
                });
              }}
            />
          </div>
          <div style={{ width: "24%" }}>
            <label>age : </label>

            <InputNumber
              value={model.age}
              onChange={(v) =>
                _setmodel((prev) => {
                  return { ...prev, age: parseInt(v) };
                })
              }
            />
          </div>
          <div style={{ width: "24%" }}>
            <label htmlFor="">genre : </label>
            <InputPicker
              block
              value={model.gender}
              data={genders.map((element) => element)}
              onChange={(gender) => {
                _setmodel((prev) => {
                  // let newgender = gender ? gender.toString() : "";
                  return { ...prev, gender };
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "20%" }}>
            <label htmlFor="">CIN : </label>
            <Input
              value={model.cin}
              onChange={(cin) =>
                _setmodel((prev) => {
                  return { ...prev, cin };
                })
              }
            />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Ville : </label>
            <Input
              value={model.city}
              onChange={(city) =>
                _setmodel((prev) => {
                  return { ...prev, city };
                })
              }
            />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Numero Passeport : </label>
            <Input
              value={model.passportNumber}
              onChange={(passportNumber) =>
                _setmodel((prev) => {
                  return { ...prev, passportNumber };
                })
              }
            />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Numero Téléphone : </label>
            <Input
              value={model.phoneNumber}
              onChange={(phoneNumber) =>
                _setmodel((prev) => {
                  return { ...prev, phoneNumber };
                })
              }
            />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="">Date Umrah : </label>
        <Input
          type="date"
          value={model.date ? model.date.substring(0, 10) : ""}
          onChange={(date) =>
            _setmodel((prev) => {
              return { ...prev, date };
            })
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "20%" }}>
          <label htmlFor="">Branche: </label>
          <Input
            onChange={(branch) => {
              _setmodel((prev) => {
                return { ...prev, branch };
              });
            }}
            value={model.branch}
          />
        </div>

        <div style={{ width: "20%" }}>
          <label htmlFor="">Rabateur </label>
          <Input
            value={model.rabateur}
            onChange={(rabateur) =>
              _setmodel((prev) => {
                return { ...prev, rabateur };
              })
            }
          />
        </div>
        <div style={{ width: "20%" }}>
          <label htmlFor="">Guide : </label>
          <Input
            value={model.guide}
            onChange={(guide) => {
              _setmodel((prev) => {
                return { ...prev, guide };
              });
            }}
          />
        </div>
        <div style={{ width: "20%" }}>
          <label htmlFor="">airlineCompany : </label>
          <Input
            value={model.airlineCompany}
            onChange={(airlineCompany) =>
              _setmodel((prev) => {
                return { ...prev, airlineCompany };
              })
            }
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "50%" }}>
          <label htmlFor="">Makkah Hotel : </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/logoMakka.png" alt="" height={50} width={50} />
            <Input
              value={model.hotelMakkahName}
              onChange={(hotelMakkahName) =>
                _setmodel((prev) => {
                  return { ...prev, hotelMakkahName };
                })
              }
            />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <label htmlFor="">Madina Hotel : </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/logoMadinah.png" alt="" height={50} width={50} />
            <Input
              value={model.hotelMadinahName}
              onChange={(hotelMadinahName) =>
                _setmodel((prev) => {
                  return { ...prev, hotelMadinahName };
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "30%" }}>
          <label htmlFor="">Saoudi Wakil : </label>
          <Input
            value={model.saoudiWakil}
            onChange={(saoudiWakil) =>
              _setmodel((prev) => {
                return { ...prev, saoudiWakil };
              })
            }
          />
        </div>
        <div style={{ width: "30%" }}>
          <label htmlFor="">Makkah Mandoub : </label>
          <Input
            value={model.makkahMandoub}
            onChange={(makkahMandoub) =>
              _setmodel((prev) => {
                return { ...prev, makkahMandoub };
              })
            }
          />
        </div>
        <div style={{ width: "30%" }}>
          <label htmlFor="">Madina Mandoub : </label>
          <Input
            value={model.madinahMandoub}
            onChange={(madinahMandoub) =>
              _setmodel((prev) => {
                return { ...prev, madinahMandoub };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AddEdit;
