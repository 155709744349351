import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { Button, Checkbox, Input, Pagination, SelectPicker } from "rsuite";
import Swal from "sweetalert2";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { surveyStateAtom } from "../../Atoms/surveyState.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { OldCustomerModel } from "../../Models/OldCustomer";
import { surveyModel } from "../../Models/surveyModel";
import { BestInKounouz, surveyStatedata } from "../../data/data";
import AddEdit from "./AddEdit";
import AddSurvey from "./AddSurvey";
import ExportExcel from "../ExportFile/ExportExcel";

function OldCustomer() {
  ////filter
  const [filterModel, setfilterModel] = useState({
    q: "",
    date: "",
    page: 1,
    take: 20,
  });
  ///models
  const [model, setmodel] = useState(new OldCustomerModel());
  const [SurveyModel, setSurveyModel] = useState(new surveyModel());
  ///// data
  const [dataMootmars, setdataMootmars] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [dates, setdates] = useState([]);
  const [Checked, setChecked] = useState(false);
  //------------- data to extract------------------
  const [dataToExtract, setdataToExtract] = useState([]);

  ////atoms
  const [state, setstate] = useRecoilState(exportAddAtom);

  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);

  const surveyStateColor = (v) => {
    if (v == 1) {
      return {
        backgroundColor: "#85ea2d",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 2) {
      return {
        backgroundColor: "grey",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 3) {
      return {
        backgroundColor: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 4) {
      return {
        backgroundColor: "orange",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 5) {
      return {
        backgroundColor: "red",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }
  };
  const branch_Color = (v) => {
    if (["SOUSSE", "SFAX", "MONASTIR", "MSAKEN", "TUNIS"].includes(v.trim())) {
      return { color: "#00cc00", fontSize: 17 };
    } else if (
      ["ARIANA", "JENDOUBA", "MEDNINE", "DJERBA", "JANDOUBA"].includes(v.trim())
    ) {
      return { color: "#0066ff", fontSize: 17 };
    } else if (["MAHDIA", "KAIROUAN"].includes(v.trim())) {
      return { color: "#ff0000", fontSize: 17 };
    } else if ("NABEUL" == v.trim()) {
      return { color: "#9900ff", fontSize: 17 };
    } else return { color: "black", fontSize: 17 };
  };
  const CustomerType = (v) => {
    if (v == 1)
      return (
        <p
          style={{
            backgroundColor: "green",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Client
        </p>
      );
    else if (v == 2)
      return (
        <p
          style={{
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Guide
        </p>
      );
    else if (v == 3)
      return (
        <p
          style={{
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Employé
        </p>
      );
  };

  const columns = [
    {
      name: "Nom",
      value: "firstName",
      value2: "lastName",
      render: (v1, v2) => {
        return <p style={{ fontSize: 17 }}>{v1 + " " + (v2 ? v2 : "")}</p>;
      },
    },
    {
      name: "Type",
      value: "customerType",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{CustomerType(v)}</p>;
      },
    },
    {
      name: "CIN",
      value: "cin",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v}</p>;
      },
    },
    {
      name: "Date",
      value: "date",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v.substring(0, 10)}</p>;
      },
    },
    {
      name: "Branche",
      value: "branch",
      render: (v) => {
        return <p style={branch_Color(v.toUpperCase())}>{v}</p>;
      },
    },
    {
      name: "Téléphone",
      value: "phoneNumber",
      render: (v) => {
        return <p style={{ color: "green", fontSize: 17 }}>{v ? v : " -- "}</p>;
      },
    },
    {
      name: "Survey",
      value: "surveyState",
      render: (v) => {
        let x = surveyStatedata.find((el) => el.value == v);
        return (
          <div style={surveyStateColor(v)}>
            <p style={{ fontSize: 17 }}>{x ? x.label : ""}</p>
          </div>
        );
      },
    },
    {
      name: "Coupon",
      value: "survey",
      render: (v) => {
        return <p>{v && v.coupon ? <Checkbox checked /> : ""}</p>;
      },
    },
  ];

  const save = () => {
    if (!model.id) {
      createAPIEndpoint("/OldCustomer")
        .create(model)
        .then(() => {
          getData();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1700,
          });
        })
        .then(() => {
          setstate((prev) => {
            return { ...prev, open: false };
          });
          reset();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur, essayez encore !!",
            showConfirmButton: false,
            timer: 1700,
          });
        });
    } else {
      createAPIEndpoint("/OldCustomer")
        .updateWithId(model.id, model)
        .then(() => {
          getData();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been updated ",
            showConfirmButton: false,
            timer: 1700,
          });
        })
        .then(() => {
          setstate((prev) => {
            return { ...prev, open: false };
          });
          reset();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur, essayez encore !!",
            showConfirmButton: false,
            timer: 1700,
          });
        });
    }
  };
  const saveSurvey = () => {
    if (model.surveyState != 0) {
      if (model.surveyState == 1 || model.surveyState == 4) {
        if (!SurveyModel.id) {
          createAPIEndpoint("/Survey")
            .create({
              ...SurveyModel,
              dateVole: model.date,
              oldCustomerId: model.id,
              guideName: model.guide,
              hotelMakkah: model.hotelMakkahName,
              hotelMadinah: model.hotelMadinahName,
            })
            .then(() => {
              if (Checked) {
                createAPIEndpoint("Message/SendCouponOldCustomer")
                  .fetchById(model.id)
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
              save();
              setsurveyState((prev) => {
                return { ...prev, open: false };
              });
            })
            .catch((err) => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erreur, essayez encore !!",
                showConfirmButton: false,
                timer: 1700,
              });
            });
        } else {
          createAPIEndpoint("/Survey")
            .updateWithId(SurveyModel.id, {
              ...SurveyModel,
              dateVol: model.date,
              oldCustomerId: model.id,
              guideName: model.guide,
              hotelMakkah: model.hotelMakkahName,
              hotelMadinah: model.hotelMadinahName,
            })
            .then(() => {
              if (Checked) {
                createAPIEndpoint("Message/SendCouponOldCustomer")
                  .fetchById(model.id)
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
              save();
              getData();
              setsurveyState((prev) => {
                return { ...prev, open: false };
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erreur, essayez encore !!",
                showConfirmButton: false,
                timer: 1700,
              });
            });
        }
      } else {
        if (Checked) {
          createAPIEndpoint("Message/SendCouponOldCustomer")
            .fetchById(model.id)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
        save();
        setsurveyState((prev) => {
          return { ...prev, open: false };
        });
      }
    } else
      toast(" !!اضف حالة المكالمة  ", {
        type: "error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };
  const getData = () => {
    createAPIEndpoint("/OldCustomer", filterModel)
      .fetch()
      .then((res) => {
        setdataMootmars(res.data.data);
        settotalCount(res.data.totalCount);
        res.data.data.map((el) => {
          if ((el.surveyState == 1 || el.surveyState == 4) && el.survey) {
            setdataToExtract((prev) => [
              ...prev,
              {
                ...el,
                ...el.survey,
                name: el.firstName + " " + el.lastName,
                bestOf: BestInKounouz.find((f) => f.value == el.survey.bestOf)
                  ? BestInKounouz.find((f) => f.value == el.survey.bestOf).label
                  : el.survey.bestOf,
              },
            ]);
          }
        });

        // setdataToExtract((prev) => [
        //   ...prev,
        //   res.data.data.map((el) => {
        //     if (el.surveyState == 1 || el.surveyState == 4) {

        //        return {
        //           ...el,
        //           ...el.survey,
        //           // bestOf: BestInKounouz.find((f) => f.value == el.survey.bestOf)
        //           //   ? BestInKounouz.find((f) => f.value == el.survey.bestOf)
        //           //       .label
        //           //   : el.survey.bestOf,
        //         },

        //     }
        //   }),
        // ]);
      })
      .catch((err) => console.log(err));
  };

  const getById = (id) => {
    createAPIEndpoint(`/OldCustomer`)
      .fetchById(id)
      .then((res) => {
        setmodel(res.data);
        res.data.surveyState == 1 || res.data.surveyState == 4
          ? createAPIEndpoint("/Survey/GetByOldCustomerId")
              .fetchById(id)
              .then((res) => setSurveyModel(res.data))
              .catch((err) => console.log(err))
          : setSurveyModel(new surveyModel());
      })
      .catch((err) => console.log(err));
  };
  const reset = () => {
    setmodel(new OldCustomerModel());
    setSurveyModel(new surveyModel());
    setChecked(false);
  };

  const handleDelete = (id) => {
    createAPIEndpoint("/Survey/GetByOldCustomerId")
      .fetchById(id)
      .then((res) => {
        createAPIEndpoint("survey")
          .delete(res.data.id)
          .then(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
          });
      });
    createAPIEndpoint("/OldCustomer")
      .delete(id)
      .then(() => {
        getData();
        reset();
      })
      .catch((err) => console.log(err));
  };

  const getDates = () => {
    createAPIEndpoint("/OldCustomer/getAll")
      .fetchAll()
      .then((res) => {
        setdates([...new Set(res.data.map((el) => el.date))]);
      })
      .catch((err) => console.log(err));
  };
  const uploadExcel = (excel) => {
    const fd = new FormData();
    fd.append("file", excel);
    createAPIEndpoint("OldCustomer/UploadExcel")
      .upload(fd)
      .then(() => getData())
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, [filterModel]);

  useEffect(() => {
    getDates();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
        <Filter search={getData}>
          <Responsive style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div style={{ width: "45%", padding: 3 }}>
                <label>Recherche : </label>
                <Input
                  placeholder="Rechercher"
                  onChange={(q) => {
                    setfilterModel((prev) => {
                      return { ...prev, q, page: 1 };
                    });
                  }}
                />
              </div>
              {/* <div style={{ width: "23%", padding: 3 }}>
                <label>CIN: </label>
                <Input
                  placeholder="Rechercher"
                  onChange={(cin) => {
                    setfilterModel((prev) => {
                      return { ...prev, cin };
                    });
                  }}
                />
              </div> */}
              <div style={{ width: "45%", padding: 3 }}>
                <label> Date de Vol: </label>
                <SelectPicker
                  block
                  data={dates.map((el) => ({
                    label: el.substring(0, 10),
                    value: el.substring(0, 10),
                  }))}
                  onChange={(date) => {
                    setfilterModel((prev) => {
                      return { ...prev, date, page: 1 };
                    });
                  }}
                />
              </div>
            </div>
          </Responsive>
        </Filter>
      </div>
      <ExportAdd
        title="اضافة معتمر"
        button
        save={save}
        saveSurvey={saveSurvey}
        ActionOnClose={reset}
        uploadExcel={uploadExcel}
        size="full"
        noExport
        AddComponent={
          <AddEdit error={Error} model={model} _setmodel={setmodel} />
        }
        AddsurveyComponent={
          <AddSurvey
            Checked={Checked}
            setChecked={setChecked}
            selectedMootamar={model}
            setselectedMootmar={setmodel}
            model={SurveyModel}
            _setmodel={setSurveyModel}
          />
        }
      />
      {/* 
      <ExportExcel
        data={dataToExtract}
        columns="name,branch,airlineCompany,date, guide, bestOf"
      /> */}
      {/* <button onClick={() => fixSurvey()}>Fix</button> */}
      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          editAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          addServeyAction={(id) => {
            getById(id);
            setsurveyState((prev) => {
              return { ...prev, open: true };
            });
          }}
          deleteAction={(id) => handleDelete(id)}
          noAdvancedActions
          columns={columns}
          rows={dataMootmars}
        />
      </div>
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

export default OldCustomer;
