import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { Button, ButtonToolbar, Form, Message, Panel } from "rsuite";
import { AuthService } from "../../API/auth.service";
import { isLogged } from "../../Atoms/auth.atom";

export default function Login(props) {
  const setLogged = useSetRecoilState(isLogged);
  const [model, setmodel] = useState({ username: "", password: "" });
  const [error, seterror] = useState("");
  function authenticate() {
    if (model.username && model.password)
      AuthService()
        .login(model)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("auth", JSON.stringify(res.data));
            setLogged(isLogged);
            seterror("");
          } else seterror(res.data.message);
        })
        .catch((er) => seterror(er.Message));
  }

  return (
    <div>
      <h3
        style={{
          textAlign: "center",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className=""
          viewBox="0 0 24 24"
          style={{ width: "50px", color: "rgb(252,179,34)" }}
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg> */}
        <img src="./logo.png" height={100} width={100} />
        <span className=" text-md" style={{ fontSize: 40 }}>
          Kounouz Travel CRM
        </span>
      </h3>
      <div style={{ margin: "auto", width: "88%", maxWidth: "700px" }}>
        <Panel
          style={{ background: "#fff" }}
          header={<h3 style={{ margin: "10px 0" }}>Connexion</h3>}
          bordered
        >
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Nom d'utilisateur ou email:</Form.ControlLabel>
              <Form.Control
                name="username"
                onChange={(username) => {
                  setmodel((prev) => {
                    return { ...prev, username };
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Mot de passe :</Form.ControlLabel>
              <Form.Control
                name="password"
                type="password"
                onChange={(password) => {
                  setmodel((prev) => {
                    return { ...prev, password };
                  });
                }}
                autoComplete="off"
              />
            </Form.Group>

            {error && (
              <Message showIcon type="error">
                {error}
              </Message>
            )}
            <br></br>
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={authenticate}>
                  Connexion
                </Button>
                <Button appearance="link">Mot de pass oublié?</Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </div>
    </div>
  );
}
