import React, { useState } from "react";
import { Button, Input } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";

const VerifyCoupon = () => {
  const [Message, setMessage] = useState("");
  const [Coupon, setCoupon] = useState({ coupon: "" });
  const messageOfVerification = () => {
    if (Coupon.coupon)
      createAPIEndpoint("/Survey/verifyCoupon")
        .create(Coupon)
        .then((res) => setMessage("Code Valide ! "))
        .catch((err) => setMessage("Code coupon non valide ! "));
    else {
      setMessage("Veillez écrire un Coupon!");
    }
  };

  return (
    <div>
      <Responsive className="p-10">
        <Filter search={""}>
          <Responsive className="p-10" xl={3} l={3} m={3}>
            <label htmlFor="">Nom :</label>
            <Input />
          </Responsive>
          <Responsive className="p-10" xl={3} l={3} m={3}>
            <label htmlFor="">Coupon :</label>
            <Input />
          </Responsive>
          <Responsive className="p-10" xl={3} l={3} m={3}>
            <label htmlFor="">date :</label>
            <Input />
          </Responsive>
        </Filter>
      </Responsive>
      <Responsive className="p-10" l={3} m={3} xl={3}>
        <Input
          width="30%"
          value={Coupon.coupon}
          onChange={(value) =>
            setCoupon((prev) => ({ ...prev, coupon: value.toUpperCase() }))
          }
        />
      </Responsive>
      <Responsive className="p-10" l={3} m={3} xl={3}>
        <Button appearance="primary" onClick={() => messageOfVerification()}>
          verifié Coupon
        </Button>
      </Responsive>
      <div
        style={{
          height: 40,
          paddingLeft: 13,
          paddingTop: 5,
        }}
      >
        {Message && (
          <p
            style={{
              fontSize: 17,
              color: Message == "Code Valide ! " ? "green" : "red",
            }}
          >
            {Message}
          </p>
        )}
      </div>
      <Responsive className="p-10">
        <Grid columns={columns} />
      </Responsive>
    </div>
  );
};

export default VerifyCoupon;
const columns = [
  {
    name: "Nom de l'utilisateur",
    value: "",
    render: () => {},
  },
  {
    name: "Date de vol",
    value: "",
    render: () => {},
  },
  {
    name: "Agence ",
    value: "",
    render: () => {},
  },
  {
    name: "Coupon",
    value: "",
    render: () => {},
  },
  {
    name: "Nom du propriétaire ",
    value: "",
    render: () => {},
  },
  {
    name: "Date de vol",
    value: "",
    render: () => {},
  },
  {
    name: "Agence ",
    value: "",
    render: () => {},
  },
];
