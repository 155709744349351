import React, { useEffect, useState } from "react";
import Responsive from "../../Components/Responsive";
import { Input, InputNumber } from "rsuite";
import { InputPicker } from "rsuite";
import { SelectPicker } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";

const AddGuide = ({ model, setmodel, clients }) => {
  const [branchs, setbranchs] = useState([]);
  const getBranch = () => {
    createAPIEndpoint("/Client/autocomplete", { q: "" })
      .fetchAll()
      .then((res) =>
        setbranchs(res.data.map((el) => ({ label: el.name, value: el.id })))
      )
      .catch((err) => console.log(err));
  };
  const gender = [
    { label: "Homme", value: "Homme" },
    { label: "Femme", value: "Femme" },
  ];
  useEffect(() => {
    getBranch();
  }, []);

  return (
    <div>
      <Responsive>
        <label htmlFor="">Nom:</label>
        <Input
          value={model.firstName}
          onChange={(firstName) =>
            setmodel((prev) => {
              return { ...prev, firstName };
            })
          }
        />
      </Responsive>
      <Responsive>
        <label htmlFor="">Prénom:</label>
        <Input
          value={model.lastName}
          onChange={(lastName) =>
            setmodel((prev) => {
              return { ...prev, lastName };
            })
          }
        />
      </Responsive>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Responsive m={2.8} l={2.8} xl={2.8}>
          <label htmlFor="">age:</label>
          <Input
            value={model.age}
            onChange={(age) =>
              setmodel((prev) => {
                return { ...prev, age: parseInt(age) };
              })
            }
          />
        </Responsive>
        <Responsive m={2.8} l={2.8} xl={2.8}>
          <label htmlFor="">genre:</label>
          <SelectPicker
            block
            searchable={false}
            data={gender}
            value={model.gender}
            onChange={(gender) =>
              setmodel((prev) => {
                return { ...prev, gender };
              })
            }
          />
        </Responsive>

        <Responsive m={2.8} l={2.8} xl={2.8}>
          <label htmlFor="">CIN:</label>
          <Input
            value={model.cin}
            onChange={(cin) =>
              setmodel((prev) => {
                return { ...prev, cin };
              })
            }
          />
        </Responsive>

        <Responsive m={2.8} l={2.8} xl={2.8}>
          <label htmlFor="">Numéro de passport:</label>
          <Input
            value={model.passportNumber}
            onChange={(passportNumber) =>
              setmodel((prev) => {
                return { ...prev, passportNumber };
              })
            }
          />
        </Responsive>
      </div>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label htmlFor="">Téléphone:</label>
        <Input
          value={model.phoneNumber}
          onChange={(phoneNumber) =>
            setmodel((prev) => {
              return { ...prev, phoneNumber };
            })
          }
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label htmlFor="">agence:</label>
        <InputPicker
          block
          data={clients}
          value={model.clientId}
          onChange={(clientId) =>
            setmodel((prev) => {
              return { ...prev, clientId };
            })
          }
        />
      </Responsive>
    </div>
  );
};

export default AddGuide;
