import React, { useEffect, useState } from "react";
import { Button, Input } from "rsuite";
import Responsive from "../../Components/Responsive";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Swal from "sweetalert2";

const Messages = () => {
  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
  });
  const [messages, setmessages] = useState({
    msg1: "",
    msg2: "",
    msg3: "",
    msg4: "",
  });
  const getdata = () => {
    createAPIEndpoint("Message", filterModel)
      .fetch()
      .then((res) => setmessages(res.data.data[0]))
      .catch((err) => console.log(err));
  };
  const handleSave = () => {
    createAPIEndpoint("Message")
      .updateWithId(messages.id, messages)
      .then(() => {
        getdata();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Messages Modifiés",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) =>
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Erreur, essayez encore !!",
          showConfirmButton: false,
          timer: 1700,
        })
      );
  };
  useEffect(() => getdata(), []);
  return (
    <div className="p-10">
      <Responsive xl={8} l={8}>
        <h3> Apres Achat </h3>
        <Input
          style={{ height: 150, marginTop: 5 }}
          as="textarea"
          value={messages.msg1}
          onChange={(msg1) =>
            setmessages((prev) => {
              return { ...prev, msg1 };
            })
          }
        />
      </Responsive>
      <Responsive xl={8} l={8}>
        <h3> Avant Le Depart </h3>
        <Input
          style={{ height: 150, marginTop: 5 }}
          as="textarea"
          value={messages.msg2}
          onChange={(msg2) =>
            setmessages((prev) => {
              return { ...prev, msg2 };
            })
          }
        />
      </Responsive>
      <Responsive xl={8} l={8}>
        <h3> Apres Le Retour </h3>
        <Input
          style={{ height: 150, marginTop: 5 }}
          as="textarea"
          value={messages.msg3}
          onChange={(msg3) =>
            setmessages((prev) => {
              return { ...prev, msg3 };
            })
          }
        />
      </Responsive>
      <Responsive xl={8} l={8}>
        <h3> Message Sondage </h3>
        <Input
          style={{ height: 150, marginTop: 5 }}
          as="textarea"
          value={messages.msg4}
          onChange={(msg4) =>
            setmessages((prev) => {
              return { ...prev, msg4 };
            })
          }
        />
      </Responsive>
      <Responsive xl={8} l={8}>
        <Button
          block
          appearance="primary"
          style={{ marginTop: 10 }}
          onClick={() => handleSave()}
        >
          Valider
        </Button>
      </Responsive>
    </div>
  );
};

export default Messages;
