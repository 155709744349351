import React, { useEffect, useState } from "react";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GeneralStats = ({ data, backgroundColors, labels }) => {
  const [percentage, setpercentage] = useState([]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const barData = {
    labels: [""],
    datasets: data.map((el, index) => {
      let label = labels[index];
      let newData = {
        label: `${labels[index]} ( ${el.count} )`,
        data: [el.count],
        backgroundColor: backgroundColors[index],
      };

      return newData;
    }),
  };
  const Percentage = () => {
    let totalCount = 0;
    let percentage = 0;
    data.map((el, index) => {
      totalCount += el.count;
    });
    data &&
      data.map((el) => {
        setpercentage((prev) => [
          ...prev,
          ((el.count / totalCount) * 100).toFixed(2),
        ]);
      });
  };
  useEffect(() => Percentage(), [data]);
  return (
    <div
      className="p-10"
      style={{
        background: "white",
        textAlign: "right",
        height: "65vh",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "80%",
          marginTop: 30,
          width: "100%",
        }}
      >
        <Bar
          options={options}
          data={barData}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: 8,
          width: "80%",
          margin: "auto",
        }}
      >
        {labels.map((el, index) => (
          <div style={{ color: backgroundColors[index] }}>
            {`${percentage[index]}%`} :{el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneralStats;
