import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckPicker, Checkbox, Input, Nav, SelectPicker } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import GeneralStats from "../../Components/Stat/GeneralStats";
import Stat from "../../Components/Stat/Stat";
import {
  BestInKounouz,
  Colors,
  GeneralStatColors,
  UmrahState,
  generalLabels,
  goodBadResponse,
  heardOf,
  surveyTitles,
  yesOrNoResponse,
} from "../../data/dashboardData";
import Hotels from "./Hotels";
import Guides from "./Guides";
import Airlines from "./Airlines";
import { surveyStatedata } from "../../data/data";

const Dashboard = () => {
  const [filterModel, setfilterModel] = useState({
    q: null,
    date: null,
    clientId: null,
    hotel: [],
  });

  const [active, setActive] = useState("general");
  const [statData, setstatData] = useState({});
  const [GeneralData, setGeneralData] = useState([]);
  const [dates, setdates] = useState([]);
  ///------------Dates after November-----------------///
  const [date2, setdate2] = useState([]);

  const [clients, setclients] = useState([]);

  const [hotels, setHotels] = useState([]);
  const [Airline, setAirline] = useState([]);

  const [answered, setAnswered] = useState(0);
  const [DataKeys, setDataKeys] = useState([]);

  const GetData = async () => {
    let res = await createAPIEndpoint("/Statistics", filterModel).fetch();
    delete res.data.hasGift;
    setstatData(res.data);
    setDataKeys(Object.keys(res.data));
    createAPIEndpoint("Statistics/oldCustomer", filterModel)
      .fetch()
      .then((res) => {
        const dataToUse = res.data
          .sort((a, b) => a.key - b.key)
          .filter((el) => el.key != 0);
        setGeneralData(dataToUse);
        setAnswered(
          dataToUse.length > 3
            ? dataToUse[0].count + dataToUse[3].count
            : dataToUse[0].count
        );
      })
      .catch((err) => console.log(err));
  };
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const labels = (index) => {
    if (Object.keys(statData)[index] == "methodOfHearingAbout")
      return heardOf
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "isFirstTime")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "generalReview")
      return UmrahState.map((el) => {
        return el.label;
      }).reverse();
    else if (DataKeys[index] == "branchReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "guideReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "airlineReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "madinahHotelReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "madinahServiceReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "makkahHotelReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "makkahServiceReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "bestOf")
      return BestInKounouz.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "isRecommended")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "isCouponUsed")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
  };
  const getDates = () => {
    createAPIEndpoint("OldCustomer/getAll")
      .fetchAll()
      .then((res) => {
        let _dates = [
          ...new Set(res.data.map((el) => el.date.substring(0, 10))),
        ];
        setdates(() => _dates.map((el) => ({ label: el, value: el })));
      })
      .catch((err) => console.log(err));
  };
  const getDates2 = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 200,
    })
      .fetch()
      .then((res) => {
        setdate2(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .map((el) => ({
              label: el.airline
                ? el.date.substring(0, 10) + " -- " + el.airline.fullName
                : "",
              value: el.date.substring(0, 10),
            }))
        );
      })
      .catch((err) => console.log(err));
  };
  const getHotels = () => {
    createAPIEndpoint("Statistics/Hotels")
      .fetch()
      .then((res) => {
        setHotels(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getAirlinesStatics = () => {
    createAPIEndpoint("Statistics/Airline", filterModel)
      .fetch()
      .then((res) => {
        setAirline(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetData();
    getDates();
    getClients();
    getHotels();
    getAirlinesStatics();
    getDates2();
  }, []);
  return GeneralData && answered ? (
    <div style={{ minHeightheight: "100vh" }}>
      <div style={{ height: 35, backgroundColor: "white" }}>
        <Navbar appearance="tabs" active={active} onSelect={setActive} />
      </div>
      {active == "general" && (
        <div>
          <Filter className="p-10" search={GetData}>
            <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Date :</label>
              <SelectPicker
                data={dates}
                block
                value={filterModel.date}
                onChange={(date) => {
                  setfilterModel((prev) => {
                    return { ...prev, date };
                  });
                }}
              />
            </Responsive>
            <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Date :</label>
              <SelectPicker
                data={date2}
                block
                value={filterModel.date}
                onChange={(date) => {
                  setfilterModel((prev) => {
                    return { ...prev, date };
                  });
                }}
              />
            </Responsive>
            <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Agence :</label>
              <SelectPicker
                data={clients}
                block
                value={filterModel.clientId}
                onChange={(clientId) => {
                  setfilterModel((prev) => {
                    return { ...prev, clientId };
                  });
                }}
              />
            </Responsive>
          </Filter>

          <Responsive className="p-10">
            <GeneralStats
              data={GeneralData}
              backgroundColors={GeneralStatColors}
              labels={generalLabels}
            />
          </Responsive>
          {Object.keys(statData).map((el, index) => {
            let title = surveyTitles[index];
            let Labels = labels(index);
            return (
              <Responsive
                xl={6}
                l={6}
                className="p-10 "
                style={{ float: "right" }}
              >
                <Stat
                  Answered={answered}
                  backgroundColors={Colors}
                  title={title}
                  data={statData[DataKeys[index]]}
                  labels={Labels}
                />
              </Responsive>
            );
          })}
        </div>
      )}
      {active == "hotels" && (
        <div>
          <Hotels hotels={hotels} />
        </div>
      )}
      {active == "Guides" && (
        <div>
          <Guides />
        </div>
      )}
      {active == "Airlines" && (
        <div>
          <Airlines Airlines={Airline} />
        </div>
      )}
    </div>
  ) : (
    <div>
      <Responsive className="p-10">
        <Skeleton variant="h1" animation="wave" height="10vh" />
      </Responsive>
      <Responsive className="p-10">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
    </div>
  );
};

export default Dashboard;

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item eventKey="general">General</Nav.Item>
      <Nav.Item eventKey="hotels">Hotels</Nav.Item>
      <Nav.Item eventKey="Airlines">Airlines</Nav.Item>
      {/* <Nav.Item eventKey="Guides">Guides</Nav.Item> */}
    </Nav>
  );
};
