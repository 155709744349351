import React, { Component } from "react";
import ReactExport from "react-export-excel";
import { BestInKounouz } from "../../data/data";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data, columns = "" }) => {
  return (
    <div>
      <ExcelFile>
        <ExcelSheet
          data={
            data.length
              ? data
              : // .filter((el) => el.survey)
                // .map((el) => ({
                //   ...el,
                //   ...el.survey,
                //   bestOf: BestInKounouz.find(
                //     (f) => f.value == el.survey.bestOf
                //   )
                //     ? BestInKounouz.find((f) => f.value == el.survey.bestOf)
                //         .label
                //     : el.survey.bestOf,
                // }))
                []
          }
          name="Employees"
        >
          {/* {data.length &&
            Object.keys(
              data[0] //  .map((el) => ({ ...el, ...el.survey }))
            )
              .filter((k) => columns.includes(k))
              .map((k) => (
                <ExcelColumn
                  label={k}
                  value={typeof data[0][k] == "object" ? "--" : k}
                />
              ))} */}
          <ExcelColumn label="Nom" value="name" />
          <ExcelColumn label="date" value="date" />
          <ExcelColumn label="brache" value="branch" />
          <ExcelColumn label="airlineCompany" value="airlineCompany" />
          <ExcelColumn label="guide" value="guide" />
          <ExcelColumn label="Nom" value="name" />
          <ExcelColumn label="Nom" value="name" />
          <ExcelColumn label="Nom" value="name" />
          <ExcelColumn label="Nom" value="name" />
          <ExcelColumn label="BestOf" value="bestOf" />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

export default ExportExcel;
