export class surveyModel {
  oldCustomerId;
  customerId;
  methodOfHearingAbout = 0;
  isFirstTime;
  generalReview = 0;
  branchReview = 0;
  guideReview = 0;
  airlineReview = 0;
  madinahHotelReview = 0;
  madinahServiceReview = 0;
  makkahHotelReview = 0;
  makkahServiceReview = 0;
  bestOf = 0;
  isRecommended;
  hasGift;
  dateVole = "";
  clientId = 0;
  guideName = "";
  hotelMakkah = "";
  hotelMadinah = "";
  note = "";
  SituationState = 0;
}
