import React, { useEffect, useState } from "react";
import Filter from "../../Components/Common/Filter";
import { Input, InputPicker, Pagination } from "rsuite";
import Responsive from "../../Components/Responsive";
import ExportAdd from "../../Components/Common/ExportAdd";
import AddEdit from "./AddEdit";
import AddSurvey from "../SurveyMakkah/AddSurvey";
import Grid from "../../Components/Grid";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { surveyModel } from "../../Models/surveyModel";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { useRecoilState } from "recoil";
import { CustomerModel } from "../../Models/Customer";
import { surveyStateAtom } from "../../Atoms/surveyState.atom";

const SurveyVo = () => {
  const [filterModel, setfilterModel] = useState({ q: "" });
  const [totalCount, settotalCount] = useState(0);
  const [clients, setclients] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [model, setmodel] = useState(new CustomerModel());
  const [surveymodel, setSurveyModel] = useState();
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);

  ////----------------------Customers-------------------////
  const getData = () => {
    createAPIEndpoint("Order/VO")
      .fetch()
      .then((res) => {
        setCustomers(res.data.data);
        settotalCount(res.data.totalCount);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getById = (id) => {
    createAPIEndpoint(`/Order/VO`)
      .fetchById(id)
      .then((res) => {
        console.log(res.data);
        setmodel(res.data);
        res.data.surrveyState == 1 || res.data.surrveyState == 4
          ? createAPIEndpoint("/Survey/GetByCustomerId")
              .fetchById(res.data.customerId)
              .then((resp) => {
                setSurveyModel(resp.data);
              })
              .catch((err) => console.log(err))
          : setSurveyModel(new surveyModel());
      })
      .catch((err) => console.log(err));
  };

  ////------------------------Clients--------------------///
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
    getClients();
  }, []);
  return (
    <div className="p-10">
      <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
        <Filter
        // advanced
        // advancedComponent={
        //   <AdvancedSearch
        //     filterModel={filterModel}
        //     _setfilterModel={setfilterModel}
        //     advancedData={surveyTitles}
        //   />
        // }
        >
          <Responsive style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: "30%" }}>
                <label>Nom ou Téléphone: </label>
                <Input
                  value={filterModel.q}
                  placeholder="Rechercher"
                  onChange={(q) => {
                    setfilterModel((prev) => {
                      return { ...prev, q, page: 1 };
                    });
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <label> Date de Vol: </label>
                <InputPicker
                  block
                  placeholder="Rechercher"
                  // data={dates}
                  // onChange={(disponibilityId) => {
                  //   setfilterModel((prev) => {
                  //     return { ...prev, disponibilityId, page: 1 };
                  //   });
                  // }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <label> Agence: </label>
                <InputPicker
                  block
                  placeholder="Rechercher"
                  data={clients}
                  onChange={(clientId) => {
                    setfilterModel((prev) => {
                      return { ...prev, clientId, page: 1 };
                    });
                  }}
                />
              </div>
            </div>
          </Responsive>
        </Filter>
      </div>
      <ExportAdd
        title="آراء المسافرين"
        // ActionOnClose={reset}
        // save={Update}
        // saveSurvey={Update}
        size="full"
        noExport
        noimportExcel
        AddComponent={<AddEdit model={model} _setmodel={setmodel} />}
        AddsurveyComponent={
          <AddSurvey
            model={surveymodel}
            // setselectedMootmar={setmodel}
            // model={SurveyModel}
            // _setmodel={setSurveyModel}
            // Checked={Checked}
            // setChecked={setChecked}
          />
        }
      />
      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          checkAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          addServeyAction={(id) => {
            getById(id);
            setsurveyState((prev) => {
              return { ...prev, open: true };
            });
          }}
          columns={columns}
          noAdvancedActions
          rows={Customers}
        />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              setfilterModel((prev) => {
                return { ...prev, page };
              });
              createAPIEndpoint("/Order/VO", {
                ...filterModel,
                page,
              })
                .fetch()
                .then((res) => {
                  setCustomers(res.data.data);
                })
                .catch((err) => console.log(err));
            }}
            onChangeLimit={(take) => {
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
              createAPIEndpoint("/Order/VO", {
                ...filterModel,
                take,
                page: 1,
              })
                .fetch()
                .then((res) => {
                  setCustomers(res.data.data);
                })
                .catch((err) => console.log(err));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyVo;
const columns = [
  {
    name: "Name",
    value: "customer",
    render: (v) => {
      return v.name;
    },
  },
];
