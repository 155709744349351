export class OldCustomerModel {
  firstName = "";
  lastName = "";
  age = 0;
  gender = "";
  city = "";
  cin = "";
  passportNumber = "";
  phoneNumber = "";
  surveyState = 0;
  date = "";
  branch = "";
  rabateur = "";
  guide = "";
  airlineCompany = "";
  hotelMakkahName = "";
  hotelMadinahName = "";
  saoudiWakil = "";
  makkahMandoub = "";
  madinahMandoub = "";
  note = "";
  customerType = 0;
}
