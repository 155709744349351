import React from "react";
import Percentage from "../../Components/Percentage";

const Hotels = ({ hotels }) => {
  return (
    <div style={{}}>
      <div style={{ textAlign: "left", background: "white" }}>
        <h2>Hotels</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <label
              style={{
                width: 20,
                background: "green",
                height: 10,
                marginRight: 5,
              }}
            />
            ممتازة
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <label
              style={{
                width: 20,
                background: "orange",
                height: 10,
                marginRight: 5,
              }}
            />
            متوسّطة
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-evenly",
            }}
          >
            <label
              style={{
                width: 20,
                background: "red",
                height: 10,
                marginRight: 5,
              }}
            />
            سيّئة
          </div>
        </div>
      </div>
      {hotels.map((el) => (
        <Percentage data={el} />
      ))}
    </div>
  );
};

export default Hotels;
