import React, { useEffect, useState } from "react";
import { CheckPicker, Input, InputNumber, InputPicker } from "rsuite";
import { genderLikeBackEnd } from "../../data/data";
import { createAPIEndpoint } from "../../API/authenticated.request";

const AddEdit = ({ model, _setmodel, error }) => {
  const [Guide, setGuide] = useState([]);
  const getGuides = () => {
    createAPIEndpoint("Guide/getAll")
      .fetchAll()
      .then((res) =>
        setGuide(
          res.data.map((el) => ({
            label: el.firstName + " " + el.lastName,
            value: el.firstName + " " + el.lastName,
          }))
        )
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => getGuides(), []);

  return model.customer ? (
    <div>
      <div style={{ backgroundColor: "#4d4cac", color: "white", padding: 15 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "30%" }}>
            <label htmlFor="">Nom : </label>
            <Input readOnly value={model.customer.name} />
          </div>

          <div style={{ width: "30%" }}>
            <label>age : </label>

            <InputNumber readOnly value={model.customer.age} />
          </div>
          <div style={{ width: "24%" }}>
            <label htmlFor="">genre : </label>
            <InputPicker
              block
              readOnly
              value={model.customer.gender}
              data={genderLikeBackEnd.map((element) => element)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "20%" }}>
            <label htmlFor="">CIN : </label>
            <Input readOnly value={model.customer.cin} />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Ville : </label>
            <Input readOnly value={model.customer.city} />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Numero Passeport : </label>
            <Input readOnly value={model.customer.passportNumber} />
          </div>
          <div style={{ width: "20%" }}>
            <label htmlFor="">Numero Téléphone : </label>
            <Input
              value={model.customer.phoneNumber}
              onChange={(v) => {
                _setmodel((prev) => {
                  let modelCopy = { ...prev };
                  let customerCopy = { ...modelCopy.customer };
                  customerCopy.phoneNumber = v;
                  modelCopy.customer = customerCopy;
                  return modelCopy;
                });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="">Date Umrah : </label>
        <Input
          readOnly
          type="date"
          value={model.date ? model.disponibility.date.substring(0, 10) : ""}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "20%" }}>
          <label htmlFor="">Branche: </label>
          <Input readOnly value={model.client.name} />
        </div>

        <div style={{ width: "20%" }}>
          <label htmlFor="">Rabateur </label>
          <Input
            value={model.rabateur}
            onChange={(rabateur) => {
              _setmodel((prev) => {
                return { ...prev, rabateur };
              });
            }}
          />
        </div>
        <div style={{ width: "20%" }}>
          <label htmlFor="">Guide : </label>
          <Input
            value={model.guideName}
            onChange={(guideName) => {
              _setmodel((prev) => {
                return { ...prev, guideName };
              });
            }}
          />
          {/* <CheckPicker
            block
            data={Guide}
            value={model.guideName.split(",")}
            onChange={(guideName) => {
              let guides = guideName.toString();
              _setmodel((prev) => {
                return { ...prev, guideName: guides };
              });
            }}
          /> */}
        </div>
        <div style={{ width: "20%" }}>
          <label htmlFor="">airlineCompany : </label>
          <Input readOnly value={model.disponibility.airline.name} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "50%" }}>
          <label htmlFor="">Makkah Hotel : </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/logoMakka.png" alt="" height={50} width={50} />
            <Input
              readOnly
              value={model.price ? model.price.hotelMakkahName : ""}
            />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <label htmlFor="">Madina Hotel : </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/logoMadinah.png" alt="" height={50} width={50} />
            <Input
              readOnly
              value={model.price ? model.price.hotelMadinahName : ""}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <div style={{ width: "30%" }}>
          <label htmlFor="">Saoudi Wakil : </label>
          <Input
            value={model.saoudiWakil}
            onChange={(saoudiWakil) => {
              _setmodel((prev) => {
                return { ...prev, saoudiWakil };
              });
            }}
          />
        </div>
        <div style={{ width: "30%" }}>
          <label htmlFor="">Makkah Mandoub : </label>
          <Input
            value={model.makkahMandoub}
            onChange={(makkahMandoub) => {
              _setmodel((prev) => {
                return { ...prev, makkahMandoub };
              });
            }}
          />
        </div>
        <div style={{ width: "30%" }}>
          <label htmlFor="">Madina Mandoub : </label>
          <Input
            value={model.madinahMandoub}
            onChange={(madinahMandoub) => {
              _setmodel((prev) => {
                return { ...prev, madinahMandoub };
              });
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default AddEdit;
