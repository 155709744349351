import React, { useState } from "react";
import Filter from "../../Components/Common/Filter";
import { Input, InputPicker } from "rsuite";
import Responsive from "../../Components/Responsive";

const Hotels = () => {
  const [filterModel, setfilterModel] = useState({ q: "" });

  return (
    <div className="p-10">
      <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
        <Filter
        // advanced
        // advancedComponent={
        //   <AdvancedSearch
        //     filterModel={filterModel}
        //     _setfilterModel={setfilterModel}
        //     advancedData={surveyTitles}
        //   />
        // }
        >
          <Responsive style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: "30%" }}>
                <label>Nom ou Téléphone: </label>
                <Input
                  value={filterModel.q}
                  placeholder="Rechercher"
                  onChange={(q) => {
                    setfilterModel((prev) => {
                      return { ...prev, q, page: 1 };
                    });
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <label> Hotels </label>
                <InputPicker
                  block
                  placeholder="Rechercher"
                  // data={dates}
                  // onChange={(disponibilityId) => {
                  //   setfilterModel((prev) => {
                  //     return { ...prev, disponibilityId, page: 1 };
                  //   });
                  // }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <label> Agence: </label>
                <InputPicker
                  block
                  placeholder="Rechercher"
                  // data={}
                  // onChange={(clientId) => {
                  //   setfilterModel((prev) => {
                  //     return { ...prev, clientId, page: 1 };
                  //   });
                  // }}
                />
              </div>
            </div>
          </Responsive>
        </Filter>
      </div>
    </div>
  );
};

export default Hotels;
