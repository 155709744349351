import React, { useEffect } from "react";
import { Checkbox, InputPicker, SelectPicker } from "rsuite";
import {
  BestInKounouz,
  branchTreatment,
  goodBadResponse,
  guide,
  heardOf,
  UmrahState,
  yesOrNoResponse,
} from "../../data/filterData";

const AdvancedSearch = ({ filterModel, _setfilterModel }) => {
  useEffect(() => console.log(filterModel), [filterModel]);
  return <div></div>;
};

export default AdvancedSearch;
