import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Input, Pagination, SelectPicker } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";

const SendMessages = () => {
  //-------- States  --------//
  const [filterModel, setfilterModel] = useState({
    q: "",
    disponibilityId: 0,
    page: 1,
    take: 20000,
  });
  const [messages, setmessages] = useState({
    msg1: "",
    msg2: "",
    msg3: "",
    msg4: "",
  });
  const [selectedMessageType, setselectedMessageType] = useState(0);
  const [selectedMessage, setselectedMessage] = useState("");
  const [dates, setdates] = useState([]);
  const [dataCustomers, setdataCustomers] = useState([]);
  const [totalCount, settotalCount] = useState([]);

  //-------- Variables --------//
  const columns = [
    {
      value: "customer",
      render: (v) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              <img
                src={Base_URL2 + "Uploads/CustomerPictures/" + v.picture}
                height={35}
                width={35}
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              />
            }
          </p>
        );
      },
    },
    {
      name: "Nom",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v.name}</p>;
      },
    },
    {
      name: "CIN",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v.cin}</p>;
      },
    },
    {
      name: "Date",
      value: "disponibility",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v ? v.date.slice(0, 10) : ""}</p>;
      },
    },

    {
      name: "Téléphone",
      value: "customer",
      render: (v) => {
        return (
          <p style={{ color: "green", fontSize: 17 }}>
            {v.phoneNumber ? v.phoneNumber : "--"}
          </p>
        );
      },
    },
  ];
  const data = [
    { label: "Avant le depart", value: 1 },
    { label: "Après retour", value: 2 },
  ];

  //-------- Functions --------//
  const messageSelection = () => {
    if (selectedMessageType == 1) {
      setselectedMessage(messages.msg2);
    } else if (selectedMessageType == 2) {
      setselectedMessage(messages.msg3);
    } else setselectedMessage("");
  };
  const getMessages = () => {
    createAPIEndpoint("Message", filterModel)
      .fetch()
      .then((res) => setmessages(res.data.data[0]))
      .catch((err) => console.log(err));
  };
  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", filterModel)
      .fetch()
      .then((res) =>
        setdates(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .map((el) => ({
              label: el.date.substring(0, 10) + " -- " + el.airline.name,
              value: el.id,
            }))
        )
      )
      .catch((err) => console.log(err));
  };
  const getCustomers = () => {
    if (filterModel.disponibilityId != 0)
      createAPIEndpoint("/Order/Umrah", filterModel)
        .fetch()
        .then((res) => {
          setdataCustomers(res.data.data);
          settotalCount(res.data.totalCount);
        })
        .catch((err) => console.log(err));
    else reset();
  };
  const sendMessages = () => {
    if (selectedMessageType == 2 && filterModel.disponibilityId != 0)
      createAPIEndpoint("Message/SendAfterReturn")
        .fetchById(filterModel.disponibilityId)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    else if (filterModel.disponibilityId != 0 && selectedMessageType == 1) {
      createAPIEndpoint("Message/SendGreat")
        .fetchById(filterModel.disponibilityId)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    } else if (filterModel.disponibilityId == 0 && selectedMessageType == 0) {
      toast(" Ajouter une Date et un Message !! ", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (filterModel.disponibilityId != 0 && selectedMessageType == 0) {
      toast(" Ajouter un Message !! ", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else
      toast(" Ajouter une Date !! ", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };

  const reset = () => {
    setdataCustomers([]);
  };
  useEffect(() => messageSelection(), [selectedMessageType]);
  useEffect(() => getCustomers(), [filterModel]);
  useEffect(() => {
    getDates();
    getMessages();
  }, []);

  return (
    <div style={{}}>
      <ToastContainer />
      <Responsive className="p-10">
        <Responsive>
          <SelectPicker
            label="Date"
            data={dates}
            value={dates.value}
            onChange={(disponibilityId) => {
              disponibilityId != null
                ? setfilterModel((prev) => {
                    return { ...prev, disponibilityId };
                  })
                : setfilterModel((prev) => {
                    return { ...prev, disponibilityId: 0 };
                  });
            }}
            style={{ width: "20vw", marginRight: 10 }}
          />
          <SelectPicker
            label="Type"
            data={data}
            value={selectedMessageType}
            onChange={(v) =>
              v == null ? setselectedMessageType(0) : setselectedMessageType(v)
            }
            style={{ width: "20vw" }}
          />
        </Responsive>
      </Responsive>

      <Responsive xl={8} l={8} className="p-10">
        <Input
          readOnly
          style={{ height: 150, margin: (0, 5) }}
          as="textarea"
          value={selectedMessage}
        />
      </Responsive>
      <Responsive className="p-10">
        <Button appearance="primary" onClick={sendMessages}>
          Envoyer
        </Button>
      </Responsive>
      <Responsive>
        <Grid columns={columns} rows={dataCustomers} />
      </Responsive>
      <div className="p-10" style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
};

export default SendMessages;
